import { useRuntimeConfig } from "nuxt/app";
import { useCustomAsyncStoryblok } from "./CustomAsyncStoryblok";

function isIncludedFilter(
  filterName,
  includeFacilityTypeGroupKey,
  checkedFacilityTypeGroupKey,
  includedFilters
) {
  return includeFacilityTypeGroupKey
    ? filterName === includeFacilityTypeGroupKey
    : !includedFilters
    ? !checkedFacilityTypeGroupKey
      ? true
      : filterName === checkedFacilityTypeGroupKey
    : includedFilters?.find((item) => item === filterName)
    ? true
    : false;
}

export async function GetFacilityTypeGroupKeys(
  includeFacilityTypeGroupKey,
  includedFilters
) {
  const config = useRuntimeConfig();
  let allowedFacilityTypeGroupKeys;
  let checkedFacilityTypeGroupKey;
  return await useCustomAsyncStoryblok("cdn/datasource_entries", {
    datasource: `${config.public["storyblokCountryPrefix"]}-country-settings`,
    cv: Date.now(),
    per_page: 1000,
  })
    .then((response) => {
      const data = response.value.datasource_entries;
      allowedFacilityTypeGroupKeys = data
        ?.find(
          (item) => item.name === "service-locator-allowed-facilityTypeGroupKey"
        )
        ?.value?.split(",");
      checkedFacilityTypeGroupKey = data?.find(
        (item) =>
          item.name ===
          "service-locator-allowed-facilityTypeGroupKey-search-checkbox-checked"
      )?.value;

      const allowedFacilityTypeGroupKeysList =
        allowedFacilityTypeGroupKeys?.map((item) => {
          return {
            name: item,
            isIncluded: isIncludedFilter(
              item,
              includeFacilityTypeGroupKey,
              checkedFacilityTypeGroupKey,
              includedFilters
            ),
          };
        });
      return allowedFacilityTypeGroupKeysList;
    })
    .catch((error) => {
      console.log(error);
    });
}
